$sexgosh_primary: #a76e25;
$sexgosh_primary_alt: #664314;
$sexgosh_backdrop: #000a;
$sexgosh_register_background: #fff;
$sexgosh_border_colors: #e57373;

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.registerContainer {
  .content {
    width: 700px !important;
    display: flex;
    justify-content: center;
    .register {
      width: 500px;

      border-radius: 20px;
      background-color: $sexgosh_register_background;

      padding: 20px;

      position: relative;

      @media (width<800px) {
        width: auto;
      }

      .modalRegister {
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 20px;

        backdrop-filter: grayscale(1);
        background-color: #000e;

        color: #fff;
        font-size: 20px;

        width: 100%;
        height: 100%;

        display: none;
        flex-direction: column;

        justify-content: center;
        align-items: center;
        align-content: center;

        img {
          width: 100px;
          margin-bottom: 30px;
        }
      }

      ._logo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;

        img {
          width: 40px;
        }
      }
      ._title {
        width: 100%;
        text-align: center;
        font-size: 35px;
        font-weight: 700;
        color: #333;
        margin-top: 20px;
      }
      ._sub {
        width: 100%;
        text-align: center;
        font-size: 15px;
        font-weight: 100;
        color: #444;
        margin-top: 10px;
      }
      ._form {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        width: 100%;

        margin-top: 20px;
        form {
          max-width: 350px;
          margin: 0px;
          padding: 0px;

          .show {
            display: flex;
            justify-content: space-between;
            // align-items: center;
            // align-content: center;
            width: 100%;
            cursor: pointer;

            span {
              font-weight: 100;
              font-size: 12px;
              &:hover {
                text-decoration: underline;
              }
            }
            label {
              width: auto !important;
            }
          }

          label {
            width: 100%;
            text-align: center;
            font-size: 15px;
            font-weight: 100;
            color: #444;
          }
          input {
            margin-bottom: 15px;
            margin-top: 5px;
            width: 100%;
            padding: 12px 20px;
            border-radius: 15px;
            border: 2px solid $sexgosh_primary;

            font-size: 17px;
            font-weight: 300;
            color: #222;
          }

          button {
            font-size: 17px;
            font-weight: 600;
            color: #fff;
            background-color: $sexgosh_primary;
            border-radius: 28px;
            padding: 12px 30px;
            margin-top: 10px;
            border: 0px;
            cursor: pointer;
            &:hover {
              background-color: $sexgosh_primary_alt;
            }
          }
        }
      }
      ._ou {
        width: 100%;
        text-align: center;
        margin: 15px 0px;
        font-size: 14px;
        color: #444;
      }
      ._a3menage {
        cursor: pointer;
        font-size: 17px;
        font-weight: 500;
        color: #fff;
        background-color: #3b204b;
        border-radius: 28px;
        padding: 5px 15px;
        border: 0px;
        display: flex;
        align-items: center;
        align-content: center;
        &:hover {
          background-color: lighten(#3b204b, 20%);
        }
        div {
          display: flex;
          align-items: center;
          align-content: center;
          margin-right: 10px;
          img {
            width: 40px;
          }
        }
      }
      ._terms {
        width: 100%;
        text-align: center;
        margin-top: 30px;
        font-size: 13px;
        font-weight: 100;
      }
    }
  }
}

.login {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  backdrop-filter: blur(5px) grayscale(1);
  background-color: $sexgosh_backdrop;

  //   display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  .back {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    padding: 10px;
    align-items: center;
    align-content: center;
    // background-color: #eee;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    img {
      width: 20px;
      margin-right: 10px;
    }
  }

  ._modal {
    overflow: hidden;

    width: 500px;

    border-radius: 20px;
    background-color: $sexgosh_register_background;

    padding: 20px;

    position: relative;

    .loaderLogin {
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 20px;

      backdrop-filter: grayscale(1);
      background-color: #000e;

      color: #fff;
      font-size: 20px;

      width: 100%;
      height: 100%;

      display: none;
      flex-direction: column;

      justify-content: center;
      align-items: center;
      align-content: center;

      img {
        width: 100px;
        margin-bottom: 30px;
      }
    }

    ._logo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;

      img {
        width: 40px;
      }
    }
    ._title {
      width: 100%;
      text-align: center;
      font-size: 35px;
      font-weight: 700;
      color: #333;
      margin-top: 20px;
    }
    ._sub {
      width: 100%;
      text-align: center;
      font-size: 15px;
      font-weight: 100;
      color: #444;
      margin-top: 10px;
    }
    ._form {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      width: 100%;

      margin-top: 20px;
      form {
        max-width: 350px;
        margin: 0px;
        padding: 0px;

        .show {
          display: flex;
          justify-content: space-between;
          // align-items: center;
          // align-content: center;
          width: 100%;
          cursor: pointer;

          span {
            font-weight: 100;
            font-size: 12px;
            &:hover {
              text-decoration: underline;
            }
          }
          label {
            width: auto !important;
          }
        }

        .forgot {
          font-size: 12px;
          margin-top: -10px;
          cursor: pointer;
          font-weight: 100;

          &:hover {
            text-decoration: underline;
          }
        }

        label {
          width: 100%;
          text-align: center;
          font-size: 15px;
          font-weight: 100;
          color: #444;
        }
        input {
          margin-bottom: 15px;
          margin-top: 5px;
          width: 100%;
          padding: 12px 20px;
          border-radius: 15px;
          border: 2px solid $sexgosh_primary;

          font-size: 17px;
          font-weight: 300;
          color: #222;
        }

        button {
          font-size: 17px;
          font-weight: 600;
          color: #fff;
          background-color: $sexgosh_primary;
          border-radius: 28px;
          padding: 12px 30px;
          margin-top: 10px;
          border: 0px;
          cursor: pointer;
          &:hover {
            background-color: $sexgosh_primary_alt;
          }
        }
      }
    }
    ._ou {
      width: 100%;
      text-align: center;
      margin: 15px 0px;
      font-size: 14px;
      color: #444;
    }
    ._a3menage {
      cursor: pointer;
      font-size: 17px;
      font-weight: 500;
      color: #fff;
      background-color: #3b204b;
      border-radius: 28px;
      padding: 5px 15px;
      border: 0px;
      display: flex;
      align-items: center;
      align-content: center;
      &:hover {
        background-color: lighten(#3b204b, 20%);
      }
      div {
        display: flex;
        align-items: center;
        align-content: center;
        margin-right: 10px;
        img {
          width: 40px;
        }
      }
    }
  }

  .recoveryPassword {
    display: none;
  }
}

#loginModalA3 {
  ._modal {
    background-color: #bbb;
  }
}
