.filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  @media (width<800px) {
    flex-direction: column;
    margin-bottom: 20px;
  }

  h1 {
    color: #666;
  }
  ._right {
    display: flex;
    align-items: center;
    align-content: center;

    @media (width<800px) {
      width: 100%;
    }

    ._city {
      cursor: text;
      display: flex;
      align-items: center;
      align-content: center;
      padding: 10px 20px;
      border-radius: 10px;
      border: 2px solid #ccc;
      font-size: 14px;
      &:hover {
        border-color: var(--main);
      }

      @media (width<800px) {
        flex: auto;
        justify-content: space-between;
      }

      img {
        margin-left: 100px;
        width: 20px;
        @media (width<800px) {
          margin-left: 0px;
        }
      }
    }
    button {
      font-size: 14px;
      padding: 10px 20px;
      border-radius: 10px;
      border: 2px solid #ccc;
      background-color: transparent;
      margin-left: 20px;
      display: flex;
      align-items: center;
      align-content: center;
      cursor: pointer;
      &:hover {
        border-color: var(--main);
      }
      @media (width<800px) {
        margin-left: 10px;
        ._mais_filtros {
          display: none;
        }
      }
      img {
        margin-left: 20px;
        width: 20px;
        @media (width<800px) {
          margin-left: 0px;
        }
      }
    }
  }
}

.promo {
  width: 100%;
  padding: 30px 20px;
  background-color: #009b4d;
  justify-content: space-between;
  margin-bottom: 20px;
  color: #fff;
  position: relative;
  overflow: hidden;
  @media (width<800px) {
    flex-direction: column;
  }
  img {
    position: absolute;
    top: -320px;
    left: -200px;
    width: 620px;
    z-index: 0;
  }
  ._left {
    z-index: 1;
    @media (width<800px) {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
  }
  ._right {
    z-index: 1;
  }
}

.results {
  flex-wrap: wrap;
  justify-content: center;
  .card {
    margin: 5px;
    width: 700px;
    background-color: transparent;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #ccc;
    @media (width<800px) {
      padding: 5px;
      border: 1px solid #ccc;
    }

    &:hover {
      border: 2px solid var(--main);
      cursor: pointer;
    }
    a {
      text-decoration: none;
      color: inherit;
    }
    ._top {
      align-items: flex-start;

      ._avatar {
        width: 200px;
        height: 200px;
        @media (width<800px) {
          width: 50px;
          height: 50px;
        }
        img {
          width: 200px;
          border-radius: 5px;
          @media (width<800px) {
            width: 50px;
          }
        }
      }
      ._data {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 100%;
        margin-left: 10px;
        h2 {
          margin: 0px;
        }
        ._infos {
          margin-bottom: 10px;
          font-size: 14px;
        }
        ._description {
          font-size: 13px;
          color: #444;
          margin-bottom: 10px;
          width: 100%;
          height: 50px;
          span {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
            line-clamp: 3;
          }
        }
        ._images {
          img {
            width: 80px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.loadMore {
  width: 100%;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 100px;
}

.cityModal {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 200;
  backdrop-filter: blur(5px) grayscale(1);

  display: none; //flex
  justify-content: center;
  align-items: center;
  align-content: center;

  width: 100%;
  height: 100%;

  ._modal {
    border: 2px solid var(--main);
    width: 100vw;
    max-width: 500px;
    height: 100vh;
    max-height: 700px;
    border-radius: 10px;
    background-color: #fff;
    padding: 30px;

    .back {
      position: relative;
      top: 0px;
      left: 0px;
      width: 100%;
      display: flex;
      padding: 10px;
      align-items: center;
      align-content: center;
      // background-color: #eee;
      font-size: 15px;
      font-weight: 400;
      cursor: pointer;
      img {
        width: 20px;
        margin-right: 10px;
      }
    }

    ._title {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 40px;
      color: #444;
      display: block;
    }

    ._text {
      font-size: 14px;
      font-weight: 400;
      display: block;
      margin-bottom: 10px;
    }

    form {
      margin: 0px;
      padding: 0px;

      input {
        padding: 10px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        border: 2px solid var(--main);
        width: 100%;
        background-color: transparent;
      }
    }
    ._output {
      width: 100%;
      margin-top: 20px;

      ._city {
        display: block;
        padding: 10px;
        border: 1px solid var(--main);
        border-radius: 5px;
        margin-bottom: 10px;
        cursor: pointer;

        &:hover {
          background-color: #eee;
        }
      }
    }
  }
}
