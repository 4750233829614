@import url("https://fonts.googleapis.com/css?family=Lexend Deca");

:root {
  --main: #690b16;
}
* {
  box-sizing: border-box;
  font-family: "Lexend Deca";
}
body,
html {
  margin: 0px;
  padding: 0px;
}

.__flex__ {
  display: flex;
  align-items: center;
  align-content: center;
}

button {
  background-color: transparent;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 500;
  border: 2px solid var(--main);
  color: var(--main);
  cursor: pointer;
  &:hover {
    border: 2px solid #000;
  }
}

.__promoButton__ {
  padding: 15px 25px;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 34px;
  border: 0px;
  color: #333;
  background-color: #fff;
  &:hover {
    box-shadow: 1px 1px 3px #222;
    border: 0px;
  }
  &:active {
    box-shadow: 0px 0px 2px #5559;
  }
}

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;

  .content {
    margin-top: 150px;
    width: 1500px;
    background-color: #eee;
    border-radius: 20px;
    padding: 20px;

    @media (width<800px) {
      margin-top: 50px;
      width: 100vw;
      padding: 5px;
    }
  }
}
