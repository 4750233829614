.navbar {
  width: 100%;
  padding: 30px;
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  background-color: var(--main);

  @media (width<800px) {
    width: 100vw;
    padding: 10px;
  }
}

.navbar .logo img {
  width: 150px;
  @media (width<800px) {
    width: 100px;
  }
}

.navbar .btn {
  padding: 15px 25px;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 34px;
  border: 0px;
  color: #333;
  background-color: #fff;
  @media (width<800px) {
    padding: 10px 15px;
    font-size: 12px;
    font-weight: 500;
  }
}

.navbar .btn_login {
  background-color: transparent;
  border: 0px;
  color: #fff;
}
