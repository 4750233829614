.profileList {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: start;
  padding: 10px;
  flex-wrap: wrap;

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin: 10px;
      padding: 10px;
      //   border: 1px solid #000;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: #000;
        color: #fff;
      }
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}

.pagination {
  margin-top: 100px;
  margin-bottom: 300px;
  padding: 30px 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #888;
  width: fit-content;
  @media (width < 800px) {
    padding: 10px;
  }
  a,
  span {
    margin: 0px 10px;
    padding: 10px;
  }
  a {
    text-decoration: none;
    color: #000;
    &:hover {
      color: #fff;
      background-color: #000;
    }
  }
}
