.containerProfile {
  background-color: #e8e8e8;
  padding-bottom: 200px;
}
.btnProfile {
  background-color: var(--main);
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  border-radius: 30px;
  padding: 10px 25px;
  border: 0px;
  @media (width<800px) {
    margin-bottom: 10px;
  }
  &:hover {
    border: 0px;
    background-color: #85101d;
  }
}
.profile {
  width: 700px !important;
  padding: 0px !important;
  background-color: #fff !important;
  min-height: 1000px;
  overflow: hidden;
  height: max-content;
  padding-bottom: 100px !important;

  @media (width<800px) {
    border-radius: 0px !important;
  }

  .top {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;

    @media (width<800px) {
      height: auto;
    }

    .spotifyEffect {
      justify-content: space-between;
      width: 100%;
      height: 100zzz%;
      padding: 30px;
      background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), #fff);

      @media (width<800px) {
        padding: 10px;
        padding-top: 30px;
      }

      .avatar {
        width: 150px;
        height: 150px;

        @media (width<800px) {
          margin-right: 10px;
        }

        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          border: 4px solid #ccc;
        }
      }

      .name {
        font-size: 40px;
        font-weight: 600;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .login {
        font-size: 14px;
        font-weight: 400;
      }
      .followers {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
      }

      .details {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
      }
    }
  }

  .middle {
    padding: 30px;
    @media (width<800px) {
      padding: 10px;
    }
    .about {
      font-size: 14px;
      font-weight: 400;
      padding: 10px;
      border: 1px solid var(--main);
    }

    .media-buttons {
      justify-content: space-between;
      margin-top: 50px;
      margin-bottom: 10px;
      span {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .media {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 5px;
      grid-row-gap: 5px;

      width: 100%;

      div {
        object-fit: cover; // Make the video cover the entire container while maintaining aspect ratio
        object-position: center;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .div-0 {
        grid-area: 1 / 1 / 3 / 3;
      }
      .div-1 {
        grid-area: 1 / 3 / 2 / 4;
      }
      .div-2 {
        grid-area: 1 / 4 / 2 / 5;
      }
      .div-3 {
        grid-area: 2 / 3 / 3 / 4;
      }
      .div-4 {
        grid-area: 2 / 4 / 3 / 5;
      }
    }
  }

  .bottom {
    margin-top: 0px;
    padding: 30px;
    @media (width<800px) {
      padding: 10px;
    }
    .sliding_options {
      width: 100%;
      // border: 1px solid #f00;
      overflow-x: auto;
      overflow-y: hidden;
      margin-bottom: 10px;

      ._content {
        display: block;
        width: max-content;
      }

      ul {
        padding-inline-start: 10px;
        margin-bottom: 0px;
      }

      ul li {
        display: inline-block;
        text-align: center;
        margin-right: 20px;
        font-weight: bold;
        color: #aaa;
        position: relative;
        padding: 10px 0px;
        // border: 1px solid red;
        cursor: pointer;

        &.active {
          color: #222;
          a:after {
            width: 100%;
            left: 0;
          }
        }

        a {
          position: relative;
          cursor: pointer;
          font-weight: 500;
          font-size: 14px;
        }

        a:after {
          background: none repeat scroll 0 0 transparent;
          bottom: -5px;
          content: "";
          display: block;
          height: 2px;
          left: 50%;
          position: absolute;
          background: var(--main);
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
          width: 0;
        }
      }

      .two.active ~ hr {
        margin-left: 25%;
      }

      .three.active ~ hr {
        margin-left: 50%;
      }

      .four.active ~ hr {
        margin-left: 75%;
      }

      hr {
        // display: none;
        height: 1px;
        width: 20px;
        margin: 0;
        background: var(--main);
        border: none;
        transition: 0.3s ease-in-out;
        margin-left: 9px;
        margin-top: 0px;
      }
    }
  }

  .loadMoreDiv {
    width: 100%;
    text-align: center;
    margin: 50px 0px;

    .remove {
      margin-top: 50px;
      border: 0px;
      color: #444;
      font-size: 13px;
    }
  }
}

.post {
  padding: 10px;
  border: 1px solid var(--main);
  border-radius: 4px;
  background-color: transparent;
  margin-bottom: 10px;

  &.premium {
    border: 2px solid var(--main);
  }

  .repost {
    display: none;
    // margin-top: 10px;
    margin-bottom: 10px;
    svg {
      margin-right: 10px;
    }
    b {
      margin-right: 4px;
    }
  }

  .head {
    display: flex;
    justify-content: flex-start;
    .avatar {
      width: 65px;
      height: 65px;
      border-radius: 4px;
      cursor: pointer;
      flex: none;
    }

    .avatar_initials {
      width: 65px;
      height: 65px;
      border-radius: 4px;
      cursor: pointer;
      flex: none;
      display: none;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-size: 16px;
      font-weight: bold;
      color: #fff;

      &.h {
        background: linear-gradient(
          331deg,
          rgba(2, 0, 36, 1) 0%,
          rgba(9, 9, 121, 1) 39%,
          rgba(0, 212, 255, 1) 100%
        );
      }

      &.m {
        background: linear-gradient(
          331deg,
          rgba(2, 0, 36, 1) 0%,
          rgba(121, 9, 81, 1) 39%,
          rgba(255, 0, 241, 1) 100%
        );
      }

      &.c {
        background: linear-gradient(
          331deg,
          rgba(2, 0, 36, 1) 0%,
          rgba(15, 121, 9, 1) 39%,
          rgba(254, 255, 0, 1) 100%
        );
      }

      &.o {
        background: linear-gradient(
          331deg,
          rgba(2, 0, 36, 1) 0%,
          rgba(121, 46, 9, 1) 39%,
          rgba(254, 255, 0, 1) 100%
        );
      }
    }
    .menu {
      flex: none;
      cursor: pointer;
    }
    .inner {
      flex: auto;
      margin-left: 10px;
      .name {
        display: inline;
        b {
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;
          &:hover {
            color: var(--main);
          }
        }
        .medals_div {
          display: inline;

          svg {
            width: 13px;
            height: 13px;
          }
        }
      }
      .status {
        margin-top: 10px;
        text-align: left;
        font-weight: bold;
      }
      .time {
        font-size: 13px;
        display: inline;
      }
      .extra {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .body {
    margin-top: 10px;

    @media (width<800px) {
      margin-right: -25px;
      margin-left: -25px;
    }

    /*
    margin-right: -10px * 2;
    margin-left: -10px * 2;

    tem que tomar cuidado pois altera também
    a margem das fotos pequenas, vai ter que se criar um
    workaround para isso.


    */

    .large_img_post {
      img {
        width: 100%;
        cursor: pointer;
      }

      .img_post_div {
        position: relative;

        .img_post_warning {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;

          display: flex;
          align-items: center;
          align-content: center;
          justify-content: center;
          flex-direction: column;
          color: #000;
          p {
            text-align: center;
            padding: 30px;
          }
        }
      }
    }
    .small_img_post {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      img {
        cursor: pointer;
        width: 18%;
        margin: 10px/2;
        border-radius: 4px;
      }
    }
  }
  .footer {
    margin-top: 10px;

    .stats {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;

      div {
        margin-left: 10px;
        font-size: 13px;
      }

      ._likes,
      ._comments {
        color: #999;
        &:hover {
          color: var(--main);
          cursor: pointer;
        }
      }
      ._repost {
        color: #999;
      }
    }
    .last_comments {
      margin-top: 0px;
      p {
        color: #555;
        margin-bottom: 5px;
        margin-top: 0px;
        cursor: pointer;
        font-size: 14px;
        b {
          color: #444;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
    .buttons {
      margin-top: 10px * 2;
      display: flex;
      justify-content: space-evenly;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        width: 30%;
        cursor: pointer;
        &:hover {
          color: var(--main);
          fill: var(--main);
        }
      }
      .likeSpan {
        position: relative;
        .likeSpan_heart {
          position: absolute;
          top: -38px;
          left: 12px;
          background-color: transparent;
          width: 100px;
          height: 100px;
          visibility: hidden;
          display: flex;
          justify-content: center;
          align-items: center;

          opacity: 0.1;
          transition: opacity 0.3s ease-in;

          img {
            width: 10px;
            height: 10px;
            transition: width 0.1s ease-in, height 0.1s ease-in;
          }

          &.is-active {
            visibility: visible;
            opacity: 1;
            img {
              width: 100px;
              height: 100px;
            }
          }
        }
      }
      img {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
    }
  }
}
